$primaryColor: rgb(121, 42, 42);


body {
  margin: 0;
  min-height: 800px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f8f8 !important;
  color: rgb(29, 29, 29);
}
.center, .benevoles {
    text-align: center;
}

.content {
    //max-width: 1200px;
    margin: 0 auto;
    padding: 2em 0.2em;
}
body h1 {
  font-size: 2em;
  font-weight: bold;
}
#navigation {
    margin-bottom: 40px;
}
body .bg-primary, button.btn-primary{
  background-color: $primaryColor !important;
}
nav .dropdown-menu-end a, svg, .navbar .dropdown svg, .fc-col-header-cell-cushion , body h1 {
  color: $primaryColor !important;
}
.content a{
  color: $primaryColor;
  font-weight: bold;
}
.benevoles svg, .navbar svg, .navbar a, .btn a{
  color: white !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.spinner {
    animation: spin infinite 3s linear;
    
    /*You can increase or decrease the timer (5s) to 
    increase or decrease the speed of the spinner*/
  }
.spinner-wrapper {
    text-align: center;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

button.btn{
    margin: 0.1em 0;
}

.table a, button a{
    color: white;
    text-decoration: none;
}

.table a:hover, button a:hover {
    color: white;
}

.card {
    color: black;
    margin: 0.2em;
}
@media only screen and (min-width: 768px) {
    .profile-infos {
        padding: 0.3em 0;
    }
}

@media only screen and (min-width: 1200px) {
  body .content {
    padding: 3em 1em;
  }
}