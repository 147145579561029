
.loadingoverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
}

.loadingelement {
    width: 150px;
    height: 100px;
    line-height: 100px;
    position: fixed;
    top: 40%;
    left: 50%;
    margin-top: -50px;
    margin-left: -75px;
    text-align: center;
    z-index: 11;
}